import React from "react";
import logo from "../../assets/images/logo-light.png";
import "../home/Home.css";

function RemoveAccount(props) {
  const industries = [
    "Email Compromise",
    "Phishing Attacks",
    "Identity Theft",
    "Behavioral Patterns",
    "Anti Money Laundering",
  ];
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-black">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-black">
        <img src={logo} className="w-[180px]" alt="" />

        <button className="border-white border-[1px] px-[20px] h-[50px] text-white">
          ↖ Get Access
        </button>
      </header>
      

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-white">
          Remove Your Data
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-white">
            <p>
              Be part of a transformative journey that is reshaping the
              financial landscape for small businesses. Together, we
              can create a thriving ecosystem where businesses have the tools
              and resources they need to flourish.
            </p>
            {/* <p className="mt-6">
              Today, some of the world’s most important institutions use Foundry
              to build safer cars, secure global supply chains, accelerate
              cancer research, and more.
            </p> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px]">
            {industries.map((industry) => (
              <div className="w-full">
                <div className="flex justify-between text-[20px] border-t-[1px] border-white py-5 text-white">
                  <span>{industry}</span>
                  <span>→</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
}

export default RemoveAccount;
