import React, { memo, useState } from "react";
import logo from "../../assets/images/logoo.png";

import { motion, useInView } from "framer-motion";
function Privacy(props) {
  const AnimateMe = memo(({ children, delay }) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const ref = React.useRef(null);

    // Use Framer Motion's useInView hook to check when element is in view
    const isInView = useInView(ref, { once: true, amount: 0.1 });

    // Trigger animation only if it has not been animated yet
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }

    return (
      <motion.div
        ref={ref}
        initial={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
        animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1, delay: delay || 0 }}
        // viewport={{ once: true, amount: 1 }}
      >
        {children}
      </motion.div>
    );
  });
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-white">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-white">
        <a href="/" className="cursor-pointer" rel="noreferrer">
          <img src={logo} className="w-[220px] h-[280px]" alt="" />
        </a>

        <button className="border-black border-[1px] px-[20px] h-[50px] text-black">
          ↖ Download
        </button>
      </header>
      <section className="w-full app-container pb-[60px] border-b-[2px] border-black">
        <div className="flex flex-wrap justify-between container mx-auto">
          <div className="w-full text-center px-[20px] md:px-0 md:pr-[100px] pt-[110px]">
            <AnimateMe>
              <h1 className="text-[40px] font-bold mb-4 leading-tight text-black">
                Privacy Policy
              </h1>
            </AnimateMe>
          </div>
        </div>
      </section>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <p>
              Introduction This Privacy Policy (“Privacy Policy”) describes how
              Vent Limited, and its affiliates will gather, use, and maintain
              your Personal Information on the Vent Platform. It will also
              explain your legal rights with respect to that information. By
              using the Vent Platform, you confirm that you have read and
              understand this Privacy Policy, and our Terms of Use (together
              referred to herein as the “Agreement”). The Agreement governs the
              use of the Vent Application. Vent will collect, use, and
              maintain information consistent with the Agreement.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px]  mb-[30px] text-black">
              <p>
                General Terms In this Privacy Policy:  Vent Limited may be
                referred to as "Vent," “we,” “our,” or “us.”  We call a user
                of the Vent Platform “User,” “Users,” “you,” or “your,” as appropriate.  The mobile
                applications (whether on iOS or Android) are referred to as the
                “Apps.”  Vent Sites, Apps and related services, information
                and communications are collectively referred to as the “Vent
                Platform.”  “Terms of Use” refers to our Terms and agreement,
                which can be found here. This Privacy Policy is incorporated
                into, and considered a part of, the Terms and agreement.
              </p>
            </div>
          </div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <p>
              Information Collection: Information you provide to Vent, Vent
              collects certain personally identifiable information about you,
              including information that can identify, relate to, describe, be
              associated with you, or is reasonably capable of being associated
              with you (“Personal Information”). Examples of Personal
              Information that Vent collects include but are not limited to:
              Contact Information. This may include your first and last name,
              postal address, telephone number, and email address. Billing Data,
              including your payment instrument number (such as a credit or
              debit card number), expiration date, and security code as
              necessary to process your payments. Identity Information. If you
              are an accountant, we may collect Personal Information, such
              as your date of birth and address, national identification number
              if applicable, together with the result of basic criminal record
              checks as provided by you, or by our Third-Party Agents (as
              defined below), as applicable and to the extent permitted by law
              in your jurisdiction, as well as such information as may be needed
              to validate your identity. Employment Information. We collect
              employment and education history, transcripts, writing samples,
              and references. Content Information. You also may choose to send
              Vent Personal Information in an email or chat message
              containing inquiries about the Vent Platform and we use this
              Information in order to help us respond to your inquiry.  Vent may work with Third-Party Agents
              to perform identity checks and criminal background checks on
              service providers, if applicable and permitted by local law, in
              order to advance our legitimate interests in ensuring the safety
              of our Users and maintaining the integrity of the Vent Platform
            </p>
          </div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <h4 className="text-black text-[16px] font-semi-bold ">4.</h4>{" "}
            Information Vent Collects Automatically We automatically collect
            certain information when you use the Vent Platform. The
            categories of information we automatically collect and have
            collected, include;{" "}
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px]  mb-[30px] text-black">
              <p className="mt-4 mb-2">
                (i) Service Use Data, including data about features you use,
                pages you visit, emails and advertisements you view, portions of
                the Vent Platform that you view and interact with, the time
                of day you browse, and your exiting pages.{" "}
              </p>
              <p className="mt-4 mb-2">
                (ii) Device Data, including data about the type of device or
                browser you use, your device’s operating system, your internet
                service provider, your device’s regional and language settings,
                and device identifiers such as IP address and Ad Id. When you
                visit and interact with the Vent Platform, Vent may
                collect certain information automatically through cookies or
                other technologies, including, but not limited to, the type of
                computer or mobile device you use, your mobile device’s unique
                device ID, the IP address of your computer or mobile device,
                your operating system, the type(s) of internet browser(s) you
                use, and information about the way you use the Vent Platform
                (“Device Information”).
              </p>
              <p className="mt-4 mb-2">
                (iii) Location Data, including imprecise location data (such as
                location derived from an IP address or data that indicates a
                city or postal code level), and, with your consent, precise
                location data (such as latitude/longitude data). When you Use
                the Vent App via a native mobile application, we use, with
                your consent when required under applicable law, GPS technology
                (or other similar technology) to determine your current location
                to determine the city you are in and display a relevant location
                map. We will not share your current location obtained in this
                manner with other Users
              </p>
            </div>
          </div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">

          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px]  mb-[30px] text-black">
              <p className="mt-4 mb-2">
                <h4 className="text-black text-[16px] font-semi-bold ">
                  5.
                </h4>{" "}
                Vent's Use of Information We collect and use information for
                business and commercial purposes in accordance with the
                practices described in this Privacy Policy. Our business
                purposes for collecting and using information include:  To
                operate and make available the Vent Platform. We use your
                data to connect you with other Users to enable the posting of
                selection for, completion of, and payment for jobs, in order to
                fulfill our contracts with Users;  For billing and fraud
                prevention, on the basis of our legitimate interests in ensuring
                a safe and secure environment in which Clients and service
                provider can meet and conduct business, and in order to comply
                with our legal obligations;  To conduct identification and
                criminal background checks, if applicable and to the extent
                permitted by local law, on Users, in order to advance our
                legitimate interests as well as for the substantial public
                interest of ensuring the safety of our Users both online and
                offline, preventing or detecting unlawful acts, protecting the
                public against dishonesty, and maintaining the integrity of the
                Vent Platform;  To analyze
                Vent Platform usage as necessary for our legitimate interest
                in improving the Vent Platform to grow our business;  To
                contact you and deliver (via email, SMS, push notifications, or
                otherwise) transactional information, administrative notices,
                marketing notifications, offers and communications relevant to
                your use of the Vent application with your consent when
                required under applicable law, as necessary for our legitimate
                interests in proper communication and engagement with our Users,
                and in promoting our business;  To provide you with customer
                support in order to fulfill our contracts with Users;  For
                internal market research for our legitimate interest in
                improving the Vent Platform to grow our business;  To
                enforce our Terms of Use and our legitimate interests in
                ensuring our Agreement is complied with; and  As otherwise set
                forth in the Agreement. Analytics and Market Analysis. Vent
                may analyze information (“Market Analysis”) as necessary for our
                legitimate interests in providing an engaging and relevant
                experience and promoting and growing the Vent platform. We
                use information to offer services to Users who express an
                interest in these services, through a poll for example, or to
                Users who can be presumed to have an interest based on results
                from our Market Analysis. We do this as necessary for our
                legitimate interests in providing an engaging and relevant
                experience, promoting our services, and growing our business.
                Cell Phone Numbers. Vent may use your cell phone number to
                call or send recurring text messages to you, using an auto
                dialer or prerecorded voice, in order to provide you
                notifications about Tasks, for marketing purposes (with your
                consent where required by law), and to administer the Vent
                Platform. Call recordings. Calls to or from Vent or its
                Third-Party Agents may be monitored and recorded for the
                purposes of quality control and training. Such calls may contain
                Personal Information
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2"></div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <h4 className="text-black text-[16px] font-semi-bold ">6.</h4>{" "}
            Information Sharing We only share the Information we collect about
            you as described in this Privacy Policy or as described at the time
            of collection or sharing, including as follows: Third Party Agents.
            We share information, including Identity Information, with entities
            that process information on our behalf for our business purposes. We
            contractually prohibit our Third-Party Agents from retaining, using,
            or disclosing information about you for any purposes other than
            performing the services for us, although we may permit them to use
            information that does not identify you (including information that
            has been aggregated or de-identified) for any purpose except as
            prohibited by applicable law. Legal Obligations. Vent and our
            Third Party Agents may disclose your Personal Information or User
            Generated Content to courts, law enforcement, governmental or public
            authorities, tax authorities or authorized third parties, if and to
            the extent required or permitted to do so by law or where disclosure
            is reasonably necessary to:
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px] mb-[30px] text-black">
              <p className="mt-4 mb-2">
                (i) comply with Vent’s legal or regulatory obligations;{" "}
              </p>
              <p className="mt-4 mb-2">
                (ii) respond to a court order, warrant or subpoena;{" "}
              </p>
              <p className="mt-4 mb-2">
                (iii) respond to a valid legal request relating to an
                investigation into alleged criminal or illegal activity; or{" "}
              </p>
              <p className="mt-4 mb-2">
                (iv) respond to or address any other activity that may expose us
                to legal or regulatory liability
              </p>
              . Vent reserves the right to disclose Personal Information from
              both private and public areas of the Vent Platform in the
              absence of a court order, warrant, or subpoena, to the extent
              permitted by applicable law, if we are given reason to believe, in
              our sole discretion, that someone is causing injury to or
              interfering with the rights of Users, the general public, or
              Vent or its partners, parents or affiliates. It is our policy
              to provide notice to Users before producing their information in
              response to law enforcement requests unless{" "}
              <p className="mt-4 mb-2">
                (i) we are prohibited or otherwise constrained by law or court
                order from doing so,{" "}
              </p>
              <p className="mt-4 mb-2">
                (ii) we have reason to believe the User’s account has been
                compromised such that the notice would go to the wrong person,
                or notice would otherwise be counterproductive or would create a
                risk to safety, or{" "}
              </p>
              <p className="mt-4 mb-2">
                (iii) it is an emergency request and prior notice would be
                impractical (in which case we may provide notice after the
                fact). Merger or Acquisition. Vent reserves the right to
                share information in connection with, or during negotiations of,
                any proposed or actual merger, purchase, sale, or any other type
                of acquisition or business combination of all or any portion of
                our assets, or transfer of all or a portion of our business to
                another business
              </p>
              Public Areas. Your profile on the Vent Platform consists of
              information about you and your business, and may include
              information such as photographs, your years in business, skills
              and expertise, hourly pay rates, feedback/rating information, and
              other information, including your username (“Profile”). The
              information in your User Profile may be visible to all Users and
              the general public
            </div>
          </div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">7.</h4>{" "}
              Your Rights and Choices You may opt-out of receiving promotional
              communications from us, including promotional communications
              related to the Vent Platform, and request the removal of your
              Personal Information from our databases, or deactivate your
              account by logging in to the application. Push Notifications. You
              have the option to receive updates and relevant offers via push
              notifications in your app. These notifications can be configured
              in the settings of your mobile device at any time. Corrections to
              Profile. You have the right to access, update, and correct
              inaccuracies in your Vent Profile at any time by logging in and
              clicking on the “Account” tab. There, you can view, update, and
              correct your Account information.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">9.</h4>{" "}
              Vent’s Security of Collected Information Your Vent account
              is password-protected so that only you and authorized Vent
              staff have access to your account information. In order to
              maintain this protection, do not give your password to anyone.
              Vent implements and maintains reasonable administrative,
              physical, and technical security safeguards to help protect
              information about you from loss, theft, misuse and unauthorized
              access, disclosure, alteration and destruction. Vent has staff
              dedicated to maintaining this Privacy Policy and other privacy
              initiatives, periodically reviewing security, and making sure that
              every Vent employee is aware of our security practices.
              Nevertheless, transmission via the internet is not completely
              secure and we cannot guarantee the security of information about
              you.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px]  mb-[30px] text-black">
              <p className="mt-4 mb-2">
                <h4 className="text-black text-[16px] font-semi-bold ">
                  8.
                </h4>{" "}
                Vent’s Information Retention Policy We retain personal data
                for as long as you are a User in order to meet our contractual
                obligations to you, and for such longer period as may be in our
                legitimate interests and to comply with our legal obligations
              </p>

              <p className="mt-4 mb-2">
                <h4 className="text-black text-[16px] font-semi-bold ">
                  10.
                </h4>{" "}
                Notification of Changes Vent's Privacy Policy is periodically
                reviewed and enhanced as necessary. This Privacy Policy might
                change as Vent updates and expands its Platform. While
                Vent will use commercially reasonable efforts to ensure the
                security of all Personal Information, we expressly disclaim any
                liability for any unauthorized access to or use of our secure
                servers and/or any and all Personal Information stored therein,
                and you agree to hold Vent harmless for any damages that may
                result therefrom. Authorized Agent. You can designate an
                authorized agent to submit requests on your behalf. However, we
                will require written proof of the agent’s permission to do so
                and verify your identity directly. Right to Non-Discrimination.
                You have the right to non-discriminatory treatment by us, should
                you exercise any of your rights. Your Rights. You have the
                following rights under certain circumstances:  A right of
                access: you have the right to obtain confirmation as to whether
                personal data concerning you are processed or not and, if
                processed, to obtain access to such data and a copy thereof;  A
                right to rectification: you have the right to obtain the
                rectification of any inaccurate personal data concerning you.
                You also have the right to have incomplete personal data
                completed, including by means of providing a supplementary
                statement;  A right to erasure: in some cases, you have the
                right to obtain the erasure of personal data concerning you.
                However, this is not an absolute right and Vent may have
                legal or legitimate grounds for keeping such data;  A right to
                restriction of processing: in some cases, you have the right to
                restrict the processing of your personal data;  A right to data
                portability: you have the right to receive the personal data
                concerning you which you have provided to Vent, in a
                structured, commonly used and machine- readable format, and you
                have the right to transmit those data to another controller
                without hindrance from Vent. This right only applies when the
                processing of your personal data is based on your consent or on
                a contract and such processing is carried out by automated
                means.  A right to lodge a complaint with the supervisory
                authority. YOUR CONTINUED USE OF THE Vent PLATFORM AFTER
                UPDATES MADE TO THIS PRIVACY POLICY TAKE EFFECT, WILL CONSTITUTE
                ACKOWLEDGEMENT AND ACCEPTANCE OF THE CHANGES. IF YOU DO NOT WISH
                TO ACKNOWLEDGE OR ACCEPT ANY UPDATES TO THIS PRIVACY POLICY, YOU
                MAY CANCEL YOUR USE OF THE Vent SERVICE.
              </p>
            </div>
          </div>
        </section>
      </AnimateMe>
    </div>
  );
}

export default Privacy;
