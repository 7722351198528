import React, { memo, useState } from "react";
import logo from "../../assets/images/logoo.png";

import { motion, useInView } from "framer-motion";
function Term(props) {
  const AnimateMe = memo(({ children, delay }) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const ref = React.useRef(null);

    // Use Framer Motion's useInView hook to check when element is in view
    const isInView = useInView(ref, { once: true, amount: 0.1 });

    // Trigger animation only if it has not been animated yet
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }

    return (
      <motion.div
        ref={ref}
        initial={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
        animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1, delay: delay || 0 }}
        // viewport={{ once: true, amount: 1 }}
      >
        {children}
      </motion.div>
    );
  });
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-white">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-white">
        <a href="/" className="cursor-pointer" rel="noreferrer">
          <img src={logo} className="w-[220px] h-[280px]" alt="" />
        </a>

        <button className="border-black border-[1px] px-[20px] h-[50px] text-black">
          ↖ Download
        </button>
      </header>
      <section className="w-full app-container pb-[60px] border-b-[2px] border-black">
        <div className="flex flex-wrap justify-between container mx-auto">
          <div className="w-full text-center px-[20px] md:px-0 md:pr-[100px] pt-[110px]">
            <AnimateMe>
              <h1 className="text-[40px] font-bold mb-4 leading-tight text-black">
                Terms & Condition
              </h1>
            </AnimateMe>
          </div>
        </div>
      </section>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
              <p>
                We call these rules our Terms of Use. They constitute a legally
                binding agreement between you and Bliu Interactive limited
                (“Vent”), governing your use of the Vent application. in these
                Terms of Use, "Vent service", "our service" or "the service"
                means the personalized service provided by Vent for discovering
                and accessing our platform, including all features and
                functionalities, recommendations and reviews, our websites, and
                user interfaces, as well as all content and software associated
                with our service. References to ‘you’ in these Terms of Use
                indicate the person who created the Vent account and whose
                payment method is charged.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
              <p>
                The use of all personal data you submit to Vent, or which we
                collect about you when you use the application is governed by
                our Privacy Policy. A copy of our Privacy Policy is available (
                <a href="/privacy" className="cursor-pointer" rel="noreferrer">
                  here
                </a>
                ). You acknowledge that by using the Vent Platform you have
                reviewed the Privacy Policy. Consent at registration and
                continued use of the Vent App constitutes your acceptance of and
                agreement to all the terms and conditions in these Terms of use
                and the Privacy policy. If any future changes to this Agreement
                are unacceptable to you or cause you to no longer follow this
                Agreement, you must deactivate your account, and immediately
                stop using the Vent platform.
              </p>
            </div>
          </div>
        </section>
      </AnimateMe>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
            MEMBERSHIP
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.1
              </h4>{" "}
              Your Vent membership will continue immediately you sign-up until
              it is terminated. To use the Vent service, you must have Internet
              access and a compatible device, providing us with one or more
              Payment Methods. “Payment Method” means a current, valid, accepted
              method of payment, as may be updated from time to time, and which
              may include payment through your account with a third party.
              Unless you cancel your membership before your billing date, you
              authorize us to charge the membership fee for the next billing
              cycle to your Payment Method. You may be subject to a video review
              process before you can register on this platform. The process may
              include but is not limited to identity verification and criminal
              background checks, using third party services appropriately.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.2
              </h4>{" "}
              Vent offers membership plans. The membership plans may have
              differing conditions and limitations, we’ll do our best to make
              sure our services are safe and working as they should, but we
              can’t guarantee you’ll have access continuously. We might decide
              to stop providing certain features or the services on the
              application completely, and we don’t have to give notice if we do.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.3 User profile
              </h4>{" "}
              To subscribe to any of our plans, you must complete a user
              profile, which you consent to be shown to other users of our
              application. You also agree to provide upon registration, accurate
              and complete information on your profile as well as updating the
              information from time to time to keep it current if the need
              arises. You agree to not provide any false or misleading
              information about your identity or location, your business, the
              beneficial owner(s) of your business, your data or other services
              your business provides. Vent reserves all right to suspend or
              terminate the account or deny access to our services to anyone who
              defaults on this section by providing false, inaccurate or
              misleading information about themselves
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.4 Account Types.
              </h4>{" "}
              Vent offers two types of accounts (Business and Accountant). Once
              you register for an account, you can add another account type to
              your existing account. You thereby promise to register for only
              one account that requires unique login details. If you sign up as
              an accountant working as an employee or agent on behalf of a
              company, you must present and warrant that you are authorized to
              enter binding contracts, on behalf of yourself and the company.
              More than one person can create an account as an employee or agent
              on behalf of the same company.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.5 Promotional Offers
              </h4>{" "}
              We may from time to time offer special promotional offers, plans
              or memberships Offers. Offer eligibility is determined by Vent, at
              its sole discretion and we reserve the right to revoke an Offer
              and put your account on hold if we determine you are not eligible.
              To determine your eligibility, we may request certain information
              from you by showing means of identification, method of payment or
              an account email address used with an existing or recent Vent
              membership.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                1.6 Termination of membership.{" "}
              </h4>{" "}
              We can take away your right to use our services at any time if you
              violate our Terms of Use. Your license to use the app becomes
              terminated and we will ask you to stop using our services
              immediately.
            </p>
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
            BILLING AND CANCELLATION
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                2.1 Billing Cycle
              </h4>{" "}
              The membership fee for access to the plans available on the Vent
              application, and any other charges you may incur in connection
              with your use of the service, such as taxes and other possible
              transaction fees, will be charged to your Payment Method on the
              specific payment date indicated on the Account page. Your payment
              date may change if your Payment Method has not been settled
              successfully, when you decide to change your subscription plan or
              if your paid membership began on a day not contained in a given
              month. We may authorize your Payment Method in anticipation of
              membership or service-related charges through various methods.
              Users of the Vent Platform contract directly with the service
              providers. Vent will not be a party to any contracts for services
              to be rendered. You are responsible for the payment of all
              services through the Vent Platform. We are not obligated to
              compensate you for any amount lost during your agreement with the
              service providers or for the Users failure to pay you for the job
              done as an accountanton our application.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                2.2 Payment Methods
              </h4>{" "}
              You remain responsible for all transactions on this platform. If a subscription payment
              is not successfully settled due to subscription expiration,
              insufficient funds, or any other payment issue relating to the use
              of this application and you do not cancel your account, we may
              deny you access to the service until we have successfully charged
              a valid Payment Method. For some Payment Methods, the issuer may
              charge you certain fees, such as foreign transaction fees or other
              fees relating to the processing of your Payment Method. Local tax
              charges may vary depending on the Payment Method used. Ensure to
              check with your Payment Method service provider for more details.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                2.3 Payment Methods Update
              </h4>{" "}
              You can update your Payment Methods by going to the "Account"
              page. We may also update your Payment Methods using information
              provided by the payment service providers. Following any update,
              you authorize us to continue to charge the applicable Payment
              Method(s).
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                2.4 Subscription Cancellation.
              </h4>{" "}
              You are allowed to cancel your Vent subscription at any time, and
              you will continue to have access to the Vent service until the end
              of your billing period. To the extent permitted by the applicable
              law, subscription payments are non-refundable, and we do not
              provide refunds or credits for any partial membership periods or
              unused Vent content. To cancel, go to the "Account" page and
              follow the instructions for cancellation. If you cancel your
              membership, your account will automatically close at the end of
              your current billing period. If you signed up for Vent using
              through a third party as a means for making payment, and you wish
              to cancel your subscription, you may need to do so through such
              third party, by turning off auto-renewal option or unsubscribing
              from the Vent service through that third party.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                2.5 Changes to the Price and Subscription Plans.
              </h4>{" "}
              We may change our subscription plans from time to time. However,
              any change made in terms of pricing will apply no earlier than 30
              days after prior notice has been given to you. If you do not wish
              to accept the price change on the use of our services or on the
              subscription fees, you are at liberty to cancel your subscription
              before the change takes effect.
            </p>

            <h4 className="text-black text-[16px] font-semi-bold  mt-4 mb-2">
              3. TERMS OF Vent SERVICE
            </h4>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.1
              </h4>{" "}
              To be eligible to make use of the Vent application and Our
              services, you must be at least 16 years of age, or the age of
              majority in your country.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.2
              </h4>{" "}
              The Vent service and any content accessed through the service are
              strictly for commercial purposes. Your Vent membership either as a
              user or as a service provider, grants you a limited,
              non-exclusive, non-transferable right to access the Vent
              application and its contents. Except for the foregoing, no right,
              title or interest shall be transferred to you.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.3
              </h4>{" "}
              You may access the Vent App primarily within the country in which
              you have established your account and only in geographic locations
              where we offer our services. 
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.4
              </h4>{" "}
              Signing up as an accountant on this application, makes you
              liable to your client if hired to perform a particular job. Using
              this application for misleading or fraudulent activities will not
              be tolerated and you will be prosecuted if suspected.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.5
              </h4>{" "}
              Signing up as an accountanton this application, makes you
              liable to your client if hired to perform a particular job. Using
              this application for misleading or fraudulent activities will not
              be tolerated and you will be prosecuted if suspected.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                3.6
              </h4>{" "}
              <p className="mt-4 mb-2">
                You agree to use the Vent service, including all features and
                functionalities associated therewith, in accordance with all
                applicable laws, rules and regulations, or other restrictions on
                use of the service. Except as explicitly authorized by us, you
                agree not to:
              </p>
              <p className="mt-4 mb-2">
                (i) remove, manipulate, circumvent, deactivate, degrade, block,
                obscure, alter or thwart any of the content protections or other
                elements of the Vent service, including the graphical user
                interface, copyright notices, and trademarks;
              </p>
             
              <p className="mt-4 mb-2">
                (ii) Bypass any security features we have put in place to
                restrict how you use our application.
              </p>
              <p className="mt-4 mb-2">
                (iii) use any robot, spider, scraper or other automated means to
                access the Vent service; decompile, reverse engineer or
                disassemble any software or other products or processes
                accessible through the Vent service;{" "}
              </p>
              <p className="mt-4 mb-2">
                (iv) use any data mining, data gathering or extraction method;
              </p>
              <p className="mt-4 mb-2">
                (v) Do anything that is against professional or academic
                standards or policies, which includes but not limited to
                submitting someone else’s work as your own or by ghost- writing
                essays, tests or certifications;
              </p>
              <p className="mt-4 mb-2">
                (vi) transmit in any way, any material designed to interrupt,
                destroy or limit the functionality of any computer software or
                hardware or telecommunications equipment associated with the
                Vent service, including any software viruses or any other
                computer code, files or programs;
              </p>
              <p className="mt-4 mb-2">
                (vii) use our services to build a similar application, identify
                or poach our users or publish any performance or benchmark
                analysis relating to the use of our application;
              </p>
              <p className="mt-4 mb-2">
                (ix) act fraudulently as a client by demanding services without
                the intention of or without providing payment in exchange for
                the services rendered.
              </p>
            </p>


            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                4 Passwords and Account Access.
              </h4>{" "}
              You are responsible for any activity that occurs through the Vent
              account. By allowing others to access the account (which includes
              access to information on viewing activity for the account), you
              agree that such individuals are acting on your behalf and that you
              are bound by any changes that they may make to the account,
              including but not limited to changes to the subscription plan. To
              help maintain control over the account and to prevent any
              unauthorized users from accessing the account, you should maintain
              control over the devices that are used to access the service and
              not reveal the password or details of the Payment Method
              associated with the account to anyone.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">5 </h4>{" "}
              Mobile App Updates and Upgrades By installing this app, you hereby
              consent to the installation of the apps updates or upgrades that
              are released on the Vent platform. These changes may cause your
              device to automatically communicate with Vent’s servers to ensure
              the proper functioning of the app and to have a record of usage
              metrics. These updates and upgrades could also affect App- related
              preferences or data that has been stored on your device and
              collect personal information as set out in our privacy policy. You
              can uninstall the App at any time
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                6 Warranties and Limitations on Liability.
              </h4>{" "}
              The Vent service is provided "as is" and without warranty or
              condition. Our service may not be uninterrupted or error-free. You
              waive all special, indirect and consequential damages against us.
              These terms will not limit any non- waivable warranties or
              consumer protection rights that you may be entitled to under the
              mandatory laws of your country of residence.
            </p>
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
            MISCELLANEOUS
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.1 Governing Law.
              </h4>{" "}
              These Terms of Use shall be governed by the laws of the Federal
              Republic of Nigeria and construed accordingly.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.2 Unsolicited Materials.
              </h4>{" "}
              Vent does not accept unsolicited materials or ideas from anyone,
              and we will not be responsible for the similarity of any of its
              contents.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.3 Customer Support.
              </h4>{" "}
              For more information about our service and its features or if you
              need assistance with your account, please visit the Vent customer
              support Center, which is accessible through the Vent.com website.
              In certain instances, Customer Service may best be able to assist
              you by using a remote access support tool through which we have
              full access to your computer or mobile device. In the event of any
              conflict between these Terms of Use and information provided by
              Customer Support or other portions of our websites, these Terms of
              Use will control the dispute resolution process.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.4 Vent’s Feedback System.
              </h4>{" "}
              Our feedback system must be used fairly and honestly. This means
              that as a user of this application, you cannot withhold payment or
              withhold a service for not being given positive feedbacks, involve
              in any form of monetary transaction for feedback, threatening a
              service provider with a negative feedback, use the system to share
              fake and unrelated news and views on politics or religion. You are
              also prohibited from offering or accepting fake services to
              increase your rating score
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.5 Survival.
              </h4>{" "}
              If any provision or provisions of these Terms of Use shall be held
              to be invalid, illegal, or unenforceable, the validity, legality
              and enforceability of the remaining provisions shall remain in
              full force and effect.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.6 Changes to these Terms of Use and Agreement.
              </h4>{" "}
              Vent may, from time to time, alter and update these Terms of Use.
              We will notify you at least 30 days before such changes apply to
              you. If you do not wish to accept the changes, you can cancel your
              subscription before they take effect. We may assign or transfer
              our agreement with you including our associated rights and
              obligations at any time and you agree to cooperate with us in
              connection with such an assignment or transfer.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                7.7 Electronic Communications.
              </h4>{" "}
              We will send you information relating to your account for example
              payment authorizations, invoices, changes in password or Payment
              Method, confirmation messages and notices in electronic form only,
              via emails to your email address provided during registration.
            </p>

            <h4 className="text-black text-[16px] font-semi-bold  mt-4 mb-2">
              8. User Agreement and Warranties. Signing up to this application
              represent and warrant that:
            </h4>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                8.1.
              </h4>{" "}
              you are at least of the legally required age in the jurisdiction
              in which you reside (18 years of age or older for Nigerian users),
              and are otherwise capable of entering into binding contracts and
              you have the right, authority and capacity to enter into this
              Agreement and to abide by the terms and conditions of this
              Agreement, and that you will so abide by them;
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                8.2
              </h4>{" "}
              services shall only be performed in a country where the Vent
              Platform has a legal presence. You will also respect the privacy
              (including without limitation private, family and home life),
              property, and data protection rights of Users and will not record
              (whether video or audio or otherwise) any Job done or any
              interaction by or with any User and/or with Vent, in connection
              with the Vent Platform without the prior written consent of Vent
              and/or the relevant User, as applicable;
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                8.3.
              </h4>{" "}
              you agree that when using or accessing the Vent Application, you
              will act in accordance with all applicable laws, whether local,
              state, or national laws or custom and in good faith;
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                8.4.
              </h4>{" "}
              if you are using or will or intend to use the Vent Platform for
              any journalistic, academic, investigative, or unlawful purpose,
              you must promptly disclose to Vent in writing, and consent must be
              given before such activities are carried out.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                8.5
              </h4>{" "}
              When using the Vent Platform, you agree that you are operating as
              a sole proprietor, partnership, limited liability company, limited
              liability partnership, corporation or other legal business entity,
              and you must have the unrestricted right to work in the area in
              which you will be working. If the work is performed in a
              jurisdiction that requires you to have a business license or
              business tax registration or insurance, you must have the required
              business license or business tax registration in order to render
              high-quality services to your client as demanded. USERS DO NOT
              HAVE ANY AUTHORITY TO ENTER INTO ANY WRITTEN OR ORAL CONTRACT,
              WHETHER IMPLIED OR EXPRESS ON BEHALF OF Vent.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                9 Third Party Advertisement.
              </h4>{" "}
              This application will have personalized advertisements from third
              party companies, who wish to advertise their goods and services on
              the Vent platform. Vent will not be liable for any transaction
              made between users and third parties prompted from the
              advertisements on the platform. Advertising and marketing are
              carried out as necessary for our legitimate interests in providing
              an engaging and relevant experience, promoting our services, and
              growing our business.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                10. Enforcing Our Terms of Use.{" "}
              </h4>{" "}
              Violation of any of these rules, can lead to the suspension of
              your account and you will not be allowed to make use of Our
              services. Kindly inform us through any available medium if you see
              someone else violating these rules. We will ensure to investigate
              potential violations, but we cannot guarantee that action will be
              taken against potential violations. This does not waiver our tight
              to take action against subsequent violations and breaches whether
              they are related to the first violation or not.
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">
                11.
              </h4>{" "}
              Vent Legal Notice and Contact The Vent Websites and mobile apps
              are owned and operated by Bliu Interactive Limited, a company
              registered in Nigeria, under the Corporate Affairs Commission. Our
              registration number is RC- Website:https://bliuinteractive.com
              Email: hello@bliuinteractive.com
            </p>

            <p className="mt-4 mb-2">
              <h4 className="text-black text-[16px] font-semi-bold ">12</h4>{" "}
              Acknowledgement and Consent I HEREBY ACKNOWLEDGE THAT I HAVE READ
              AND UNDERSTAND THE FORGOING TERMS OF USE, AS WELL AS THE PRIVACY
              POLICY, AND AGREE THAT MY USE OF THE Vent APPLICATION IS AN
              ACKNOWLEDGEMENT OF MY AGREEMENT TO BE BOUND BY THE TERMS AND
              CONDITIONS OF THIS AGREEMENT.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Term;
